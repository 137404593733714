/**
  EDIT THIS AND YOU'RE FIRED!

  This sheet is globally accessible in all application sheets
  DO NOT add sheets with styles, this should ONLY be used for mixins and variables
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  font-family: "Source Sans Pro", sans-serif;
  background: #eee;
}
body svg {
  height: 13px;
  fill: #999999;
}
body strong {
  font-weight: 600;
}
body .column.name {
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
body .column.name span {
  color: #757575;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
}
body .column.name strong {
  color: #555555;
  font-size: 18px;
  text-transform: capitalize;
}
body .column.icon {
  padding-left: 20px;
}

.panel-no-header, .panel .login .panel-body {
  padding-top: 40px;
}
@media (max-width: 425px) {
  .panel-no-header, .panel .login .panel-body {
    padding-top: 30px;
  }
}

.content--inside {
  margin-top: 80px;
  position: relative;
}
@media (max-width: 425px) {
  .content--inside {
    margin-top: 0;
  }
}

.login p {
  font-size: 16px;
  color: #333;
}
.login .btn-link {
  font-size: 14px;
}
.login .btn-link:focus {
  box-shadow: none;
}
.login .btn-secondary {
  border-color: #dddddd;
}
.login .btn-login {
  margin-top: 14px;
}
.login .third-party {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.login .third-party img {
  margin-left: 15px;
}
.login .third-party a {
  text-transform: capitalize;
  color: #757575;
  text-decoration: none;
  border-top: 1px solid #e5e5e5;
  transition: all 100ms ease-in-out;
}
.login .third-party a:hover, .login .third-party a:focus {
  border-top-color: #c4c4c4;
  outline: none;
}
.login .third-party a:active {
  color: #555;
}