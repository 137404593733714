@import "styles/resources";
.phone-input.form-group {

  .input-group {
    overflow: visible;
  }

  .phone-input-wrapper {
    position: relative;

    input[type=tel].form-control {
      padding-left: 55px;
    }

    .country-select {

      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;

      .Select {
        flex-basis: 60px;
        z-index: 100;
      }

      .Select__control {
        background-color: transparent !important;
        border: none;
        height: 100%;
      }

      .Select__dropdown-indicator {
        padding-left: 0;
        padding-right: 4px;
      }

      .Select__single-value {
        width: 18px;
      }

      .flag-icon {
        width: 18px;
      }

      .Select__single-value .flag-icon {
        transform: translateY(-0.5px);
      }

      .option-label {
        margin-left: 10px;
        margin-right: 4px;
      }

      .option-code {
        color: #999;
      }

      .form-control {
        padding-bottom: 0.72rem;
        padding-left: 0.2rem;
        padding-top: 0.72rem;
      }
    }
  }

}
