@import "styles/resources";
.forgot {
  .panel-title {
    border-bottom: 1px solid #e5e5e5;
    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
    }
  }
  p {
    color: #666666;
    font-size: 14px;

    .user-email {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-top: 10px;
    }
  }
  .spam-notice {
    padding: 20px;
    background-color: #fafafa;
    border-radius: 8px;
    margin-top: 30px;

    h5 {
      font-size: 12px;
      font-weight: 600;
      color: #666666;
    }
    p {
      color: #757575;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
