@import "styles/resources";
.change-password-page {
  small {
    font-size: 13px;
  }

  .invalid-token {
    p {
      font-size: 16px;
    }
  }

  fieldset {
    padding-left: 0;
    padding-right: 0;
  }

  .tos-disclaimer {
    margin: 0 0 24px;
    color: $light_mid_gray;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.285714; // 18 / 14

    @media (max-width: $break-medium) {
      margin: 0 0 16px;
    }
  }
}
