@import "styles/resources";
@import "app/styles/variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body.dialog-open {
  overflow: hidden;
}

.dialogs--holder {
  // Just holds the dialogs
}

.dialog-kit {
  background: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  display: block;
  font-family: "Source Sans Pro", Arial, sans-serif;
  min-height: 100px;
  position: absolute;
  width: auto;
  z-index: 200;

  &.scrollable {
    .dialog-body {
      max-height: calc(100vh - 87px - 71px - 20px); // footer - header - margin
      overflow-y: auto;
    }
  }

  &.visible {
    display: block;
  }

  &.dark {
    background: rgb(0 0 0 / 80%);
    box-shadow: none;
  }

  >.dialog-button {
    position: relative;
    right: 24px;
    top: 24px;
  }

  .Select__menu {
    z-index: 2;
  }

  .dialog-header {
    border-bottom: 1px solid #ccc;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    padding: 24px 0 22px;

    h1 {
      text-align: left;
      color: #333;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    &.seamless {
      border-bottom: 0 none;
    }
  }

  .dialog-subheader {
    float: right;
    margin: 12px 30px 0 0;
  }

  .dialog-button {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    color: #999;
    float: right;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 22px;
    margin-left: 5px;
    min-width: 24px;
    padding: 0 7px;
    text-align: center;
    text-transform: uppercase;

    &.icon {
      padding: 0;
      text-align: center;
    }

    &.small {
      font-size: 12px;
    }

    &:hover {
      border-color: #ccc;
      color: #666;
    }

    &.button-close {
      cursor: pointer;
    }
  }

  .content-container {
    float: left;
    padding: 30px;
  }

  .dialog-header,
  .dialog-body,
  .dialog-footer {
    margin-left: 30px;
    margin-right: 30px;
  }

  .dialog-menu {
    background: #eee;
    margin-bottom: -2000px;
    padding-bottom: 2000px;

    li {
      border-bottom: 1px solid #333;

      a {
        color: #777;
        display: block;
        font-weight: bold;
        line-height: 50px;
        padding: 0 20px 0 15px;
        white-space: nowrap;
      }

      &.selected {
        background: #555;

        &::before {
          border-color: transparent #eee transparent transparent;
          border-style: solid;
          border-width: 10px;
          content: "";
          float: right;
          height: 0;
          margin-top: 15px;
          width: 0;
        }

        a {
          color: #fff;
        }
      }
    }

    &.as-side {
      li {
        border-color: #ddd;

        a {
          color: #999;
          font-weight: 600;
          padding-left: 21px;
          text-transform: uppercase;
        }

        &.selected {
          background: #ddd;

          a {
            color: #333;
          }

          &::before {
            border-color: transparent #fff transparent transparent;
          }
        }

        .avatar-img {
          height: 23px;
          margin-right: 10px;
          margin-top: -4px;
          width: 23px;
        }

        &.user-info {
          color: #333;
          font-size: 14px;
          height: 20px;
          line-height: 25px;
          overflow: hidden;
          padding: 25px 22px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .dialog-footer {
    border-top: 1px solid #ccc;
    color: #333;
    padding: 20px 0 30px;
    // Default button styles for the footer (Could also use large styles, etc.)

    .button-kit {
      font-size: 13px;
      height: 26px;
      line-height: 26px;

      &.right.pad {
        margin-right: 13px;
      }
    }
  }

  .dialog-body {
    font-size: 16px;
    margin: 0 15px;
    overflow-y: auto;
    padding: 20px 15px 30px;

    &.scrollable {
      max-height: calc(100vh - 87px - 71px - 20px); // footer - header - margin
      overflow-y: auto;
    }

    &.scrollable-no-footer {
      max-height: calc(100vh - 71px - 20px); // header - margin
      overflow-y: auto;
    }

    &.loading {
      text-align: center;
    }

    section {
      border-bottom: 1px solid #ccc;
      padding: 20px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .grid:last-child {
        .form-item {
          padding-bottom: 5px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }

      &.seamless {
        border: 0 none;
      }

      .select2-container .select2-choice {
        padding: 0 10px;
      }

      .select2-container .select2-choice,
      .select2-container .select2-choices {
        border-color: #ccc;
      }
    }
  }

  h1 {
    color: #666;
    font-size: 30px;
    font-weight: 400;
  }

  h2 {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    color: #999;
    font-size: 13px;
    font-weight: 600;
    margin: 20px 0 15px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 0;
    }

    +p {
      margin-top: -15px;
    }
  }

  hr {
    background: #ccc;
    border: none;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  .form-item {
    &.flexible {
      padding: 0 10px 15px;

      &.wide-gutter {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &.weight-fourth {
      width: 25%;
    }

    &.weight-third {
      width: 33%;
    }

    &.weight-half {
      width: 50%;
    }

    &.weight-more {
      width: 66%;
    }

    &.weight-most {
      width: 75%;
    }

    &.weight-max {
      width: 100%;
    }

    >label {
      color: #999;
      font-size: 12px;
      font-weight: 600;

      &.title-text {
        color: #999;
        padding-bottom: 2px;
      }
    }
  }

  p {
    font-size: 16px;
    margin: 10px 0;
  }

  &.alert-dialog {
    p {
      color: #666;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }

    strong {
      font-weight: 600;
    }
  }

  .button-kit {
    font-family: "Source Sans Pro", Arial, sans-serif;
  }

  .form-select {
    &.large {
      height: 58px;

      .dropdown {
        height: 60px;
        width: 50px;

        i {
          color: #999;
          font-size: 28px;
          line-height: 62px;
        }
      }

      select {
        color: #666;
        font-size: 24px;
        height: 58px;
      }
    }
  }

  .small {
    color: #999;
  }

  .input-tip {
    padding-left: 5px;
    padding-top: 4px;
  }
}

.dialog-kit {
  .dialog-header {
    &.billboard {
      border-bottom: none;
      margin: 0;
      padding: 50px 30px;

      &.green {
        background-color: #51a33d;
      }

      &.red {
        background-color: #c66;
      }

      &.yellow {
        background-color: #d6d41e;
      }

      &.blue {
        background-color: #558aaf;
      }

      .dialog-button {
        border-color: white;
        color: white;
        margin-top: 13px;
      }

      h2 {
        border-radius: 4px 4px 0 0;
        color: white;
        font-size: 72px;
        font-weight: 600;
        line-height: 50px;
        text-align: center;
      }
    }
  }

  .hero-text {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    margin-top: 36px;
    text-align: center;

    .major {
      color: #333;
      font-size: 48px;
      font-weight: 600;
    }

    .minor {
      color: #999;
      font-size: 20px;
      margin-top: 12px;
    }
  }

  .text-center {
    text-align: center;
  }

  .body-text {
    color: #666;
    font-size: 15px;
  }

  .error-text {
    color: #af555b;
  }

  .wiw-loader {
    //margin-top: 20px;
    background: transparent;
    display: block;
    height: 60px;
    margin: 20px auto 0;
    width: 55px;

    .dot {
      display: inline-block;
      position: relative;
    }
  }

  /* ----------------------------
  SOCIAL DIALOG
  ---------------------------- */

  &.social-dialog {
    .dialog-body {
      text-align: center;

      h1 {
        color: #666;
        font-size: 42px;
        font-weight: 600;
        line-height: 42px;
        margin: 25px 0;
        padding: 0 30px;
      }

      p {
        color: #666;
        font-size: 18px;
        margin: 25px 0;
      }

      .button-close {
        margin-top: 40px;
      }

      .social-list {
        margin-bottom: 25px;

        li {
          display: inline-block;

          a {
            background-position: center 18px;
            background-repeat: no-repeat;
            background-size: 75px;
            border: 1px solid white;
            box-sizing: border-box;
            display: block;
            height: 140px;
            margin: 0 3px;
            padding-top: 107px;
            width: 140px;

            span {
              color: #999;
              font-size: 14px;
              text-transform: uppercase;
            }

            &:hover {
              border-color: #ddd;

              span {
                color: #666;
              }
            }
          }
        }
      }
    }
  }

  .dialog__mini-info {
    background: #f0f0f0;
    border-radius: 2px;
    color: #333;
    display: block;
    font-style: italic;
    margin: 0 0 10px;
    padding: 5px 10px;
  }
}

$dialog-scroll-padding: 20px;
$dialog-min-height: 400px;

.dialog-kit-scroll {
  align-items: center !important;
  box-sizing: border-box;
  display: flex !important;
  height: 100%;
  justify-content: center !important;
  left: 0;
  padding: $dialog-scroll-padding 0;
  position: fixed;
  top: 0;
  width: 100%;
  // using popover since modals are using modal
  z-index: 1060;

  &.dimmed {
    background: rgb(0 0 0 / 50%);
  }

  .dialog-kit {
    max-width: calc(100vw - 4px);
    position: static !important;

    .dialog-header,
    .dialog-footer,
    .dialog-sticky {
      bottom: -$dialog-scroll-padding;
      top: -$dialog-scroll-padding;
      z-index: 1;
    }

    .dialog-header,
    .dialog-footer {
      bottom: -($dialog-scroll-padding + 10);
    }
  }
}

// If the vertical height is below $dialog-min-height
// scroll the dialog normally to avoid scroll inception
@media screen and (max-height: $dialog-min-height + ($dialog-scroll-padding * 2)) {
  .dialog-kit-scroll .dialog-kit {

    .dialog-header,
    .dialog-footer,
    .dialog-sticky {
      bottom: auto;
      position: static;
      top: auto;
    }
  }
}

// On a phone, scroll the dialog normally
@include media-breakpoint-down(sm) {
  .dialog-kit-scroll {
    padding: 2px 0;

    .dialog-kit {

      .dialog-header,
      .dialog-footer,
      .dialog-sticky {
        bottom: auto;
        position: static;
        top: auto;
      }
    }
  }
}

.dialog-option-wide {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  padding: 12px 16px;
  text-align: left;
  width: 100%;

  &:not(:first-child) {
    margin-top: 9px;
  }

  &:focus {
    border-color: #51a33d;
    outline: none;
  }

  .wiw-icon {
    color: #ccc;
    float: right;
    height: 24px;
    left: 5px;
    position: relative;
  }
}
