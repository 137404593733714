@import "styles/resources";
.reset-password-pane {
  .password-fields {
    .input-group-append {
      background-color: #fff;
    }

    .form-text,
    .form-control-feedback {
      color: #fff !important;
    }

    .password-requirements {
      color: $white !important;

      .requirement-item {
        color: $white !important;

        .wiw-icon {
          color: $white !important;
        }

        .requirement-hint {
          color: $white !important;
        }
      }
    }
  }

  .form-check {
    color: $white;
    font-size: 13.8px;
    opacity: 0.6;
    padding-left: 0 !important;

    .wiw-checkmark {
      margin-left: -3px;
      margin-right: 3px;
      margin-top: -3px;
    }

    &.ok {
      opacity: 100;
    }
  }

  .btn {
    margin-top: 8px;
  }

  .invalid-token {
    p {
      color: $white;
      font-size: 18px;
      line-height: 23px;
    }
  }
}
