@import "styles/resources";
.choose {
  font-family: 'Source Sans Pro', serif;
  font-style: normal;
  text-align: center;

  .panel-body-content {
    @media (min-width: $break-medium) {
      padding-left: 80px !important;
      padding-right: 80px !important;
    }
  }

  .choose-subheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 1.5rem;
  }

  .choose-card {
    cursor: pointer;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
    gap: 8px;

    .wiw-chevron-right {
      color: #999999;
      font-size: 24px;

      &:before {
        vertical-align: unset;
      }
    }

    img {
      align-self: center;
      margin-bottom: 24px;
    }

    .choose-card-header {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .choose-card-subheader {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #757575;
      text-align: left;
    }
  }
}
