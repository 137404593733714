@import "styles/resources";
.password-fields {
  margin-bottom: 1.5rem;

  .wiw-lock {
    height: 13px;
  }

  [name="password"] {
    height: auto;
  }

  .input-group-append .input-group-text {
    cursor: pointer;
    font-size: 12px;
    line-height: 1.3;
    padding: 0;
  }

  .password-view-toggle, .password-clear {
    background-color: transparent;
    border: 0;
    color: $primary;
    display: inline-block;
    font-size: 12px;
    line-height: 1.1;
    padding: 11px 18px;
  }

  .password-view-toggle {
    padding-left: 4px;
  }

  .password-clear {
    padding-right: 4px;
  }

  .form-group {
    margin-bottom: .5rem;
  }

  .password-requirements {
    display: flex;
    flex-direction: column;
    color: $light_mid_gray;

    .requirement-item {
      margin-top: .5rem;

      .wiw-icon {
        position: relative;
        top: 3px;
        margin-right: .5rem;
      }

      &.met {
        .wiw-icon {
          color: $base_color;
        }
      }

      &.not-met {
        color: $dark_gray;

        .requirement-hint {
          color: $light_mid_gray;
        }

        .wiw-icon {
          margin-right: .5rem;
          color: $gray;
        }
      }

      &.invalid {
        color: $danger;
        .wiw-icon {
          color: $danger;
        }
        .requirement-hint {
          color: $danger;
        }
      }
    }
  }
}
