@import "styles/resources";
.join-card {
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid #ddd;

  .workplace-details {
    padding: 20px;

    .workplace-info-container {
      display: flex;
      align-items: flex-start;

      .account-logo {
        margin-right: 16px;
      }

      .company {
        margin-bottom: 0;
      }
      .address {
        font-weight: 600;
        color: #757575;
      }
    }
  }

  .btn-cancel {
    margin-top: 15px;
    margin-bottom: -5px;
  }
}
