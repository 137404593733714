@import "styles/resources";
.mfa-setup {
  .col>h1 {
    color: #333;
    font-weight: normal !important;
    margin-top: -10px !important;
  }

  p {
    font-size: 16px;
  }

  small {
    color: #666;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .qrimg {
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .applications {
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .application {
    margin-bottom: 10px;
  }

  textarea {
    border: 1px solid #ddd;
    padding: 15px;
    width: 100%;
  }

  ol {
    counter-reset: item;
    margin: 0 0 1.5em;
    padding: 0;
  }

  ol>li {
    counter-increment: item;
    font-size: 16px;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 2em;
    text-align: left;
    text-indent: -17px;
  }

  ol>li::before {
    content: counter(item) ".";
    display: inline-block;
    font-weight: bold;
    padding-right: 0.5em;
    text-align: right;
    width: 1em;
  }

  .mfa-configure-buttons {
    .col {
      display: flex;
    }
  }

  .dividertext {
    margin-top: 40px;
    text-align: center;

    span {
      background-color: #fff;
      font-size: 14px;
      font-weight: 600;
      padding: 5px;
      position: relative;
      text-transform: uppercase;
      top: -29px;
    }
  }
}
